import React, { Component } from "react";

import ContentWrapper from "./ContentWrapper";
import MobileMenu from "./MobileMenu";
import Menu from "./Menu";

let prevScrollY = 0;

const menuItems = [
  "about",
  // "schedule",
  //"announcements",
  "team",
  "partners",
  //"projects",
  "faq",
  "discussion"
];

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHeaderVisible: true,
      isMobileMenuVisible: false,
      isLogoVisible: false,
      isAtTheTop: true,
      standaloneVersion: props.standaloneVersion || false
    };
  }

  componentDidMount() {
    window.onscroll = () => {
      if (!this.props.standaloneVersion) {
        const isAtTheTop = window.scrollY === 0;
        const isLogoVisible = window.scrollY > 200;
        const isHeaderVisible =
          window.scrollY < prevScrollY || window.scrollY < 100;
        if (
          this.state.isHeaderVisible !== isHeaderVisible ||
          this.state.isLogoVisible !== isLogoVisible ||
          this.state.isAtTheTop !== isAtTheTop
        ) {
          this.setState({ isHeaderVisible, isLogoVisible, isAtTheTop });
        }
        prevScrollY = window.scrollY;
      }
    };
  }

  disableScrolling = (e) => e.preventDefault();

  enableScrolling = () => true;

  onMobileMenuClicked = () => {
    this.setState({ isMobileMenuVisible: !this.state.isMobileMenuVisible });
    document.body.style.overflow = this.state.isMobileMenuVisible
      ? ""
      : "hidden";
  };

  render() {
    // Throws an error during build command
    try {
      document.ontouchmove = this.state.isMobileMenuVisible
        ? this.disableScrolling
        : this.enableScrolling;
    } catch (e) { }

    return (
      <div>
        <div
          data-cy="mlh-banner"
          css={{
            width: "100%",
            position: "fixed",
            top: this.state.isHeaderVisible ? "0px" : "-100px",
            transition: "0.5s",
            backgroundColor: "#ffffff",
            opacity: this.state.isMobileMenuVisible
              ? "1.0"
              : (this.state.isHeaderVisible && "0.95") || "0",
            zIndex: "5",
            "@media(min-width:860px)": {
              backgroundColor: this.props.standaloneVersion
                ? "#ffffff"
                : this.state.isAtTheTop
                  ? "rgba(0,0,0,0)"
                  : "#ffffff"
            }
          }}
        >
          {/*
          <div
            css={{
              display: "flex",
              height: "2.5rem",
              position: "sticky",
              width: "100%",
              top: "0",
              padding: "1rem 0.5rem 1rem 0.5rem",
              background: "#00205b",
              "@media(max-width:860px)": {
                height: "5rem"
              }
            }}
          >
            <p
              css={{
                margin: "auto auto",
                color: "white",
                textAlign: "middle",
                lineHeight: "0.5rem",
                fontSize: "14px;",
                fontWeight: "500",
                "@media(max-width:640px)": {
                  lineHeight: "1.5rem"
                },
                "@media(max-width:500px)": {
                  fontSize: "11px"
                }
              }}
            >
              3rd All Sri Lankan Asteroid Searching Campaign (ASASC 2020)
              <a href="/ASASC2020v3/" css={{
                color: "lightblue", "@media(max-width:500px)": {
                  fontSize: "12px"
                }
              }}> Click Here to go to campaign page</a>
            </p>
          </div> */}
          <ContentWrapper>
            <div
              css={{
                "@media(max-width:860px)": {
                  display: "grid",
                  gridTemplateColumns: "auto auto"
                }
              }}
            >
              <Menu
                menuItems={this.props.menuItems || menuItems}
                imgCss={this.state.isLogoVisible}
                standaloneVersion={this.props.standaloneVersion}
              />
              <MobileMenu
                menuItems={this.props.menuItems || menuItems}
                isMenuVisible={this.state.isMobileMenuVisible}
                toggleMenu={this.onMobileMenuClicked}
              />
            </div>
          </ContentWrapper>
        </div>
      </div >
    );
  }
}

export default Header;
