import React from "react";

import BriefSummary from "./BriefSummary";
import SellingPoints from "./SellingPoints";

const title = "Astronomical Research Institute Sri Lanka";
const description = "Astronomical Research Institute, Sri Lankais functioned under Celestia Group. We are the most qualified and most experienced Astronomical institute in Sri Lanka. We provide data analysis and research opportunities for our members and network, combining with leading international research institutes. We organize astronomical training programmes, night camps, workshops, stargazing events, public outreach programmes and any technical or enthusiastic sessions related to Astronomy. sponsorship and endorsement of international astronomical organizations. Our team is committed for the continuous improvement of Astronomy and Space science sectors in Sri Lanka by making young astronomers, required for the development of Science and technology fields in the Nation.";

const About = () => (
  <section id="about">
    <BriefSummary title={title} description={description} />
    <SellingPoints />
  </section>
);

export default About;
