import React from "react";

import Header from "./Header";
// import ActionButton from "./ActionButton";
import ContentWrapper from "./ContentWrapper";
import Landings from "./Landings";
import landingImg from "../assets/img/side_images/undraw_To_the_stars_qhyy.svg";
import logo from "../assets/img/logo/qhacksCrown-colored.svg";
import word from "../assets/img/logo/qhacksWordmark-colored.svg";
import backgroundCubes from "../assets/img/backgrounds/backgroundCubes.svg";
import ARISLogo from "../assets/img/logo/aris.jpg";

// const applyButtonCSS = {
//   margin: "25px 0 100px 0",
//   "@media(max-width: 820px)": {
//     margin: "30px 0 45px auto"
//   }
// };

const Landing = () => (
  <div
    id=""
    css={{
      width: "100%",
      background: `url(${backgroundCubes}) no-repeat center center`,
      backgroundSize: "cover",
      "@media(max-width: 600px)": {
        minHeight: "600px"
      }
    }}
  >
    <Header standaloneVersion={false} />
    <ContentWrapper>
      <div
        css={{
          position: "relative",
          textAlign: "left",
          display: "flex",
          paddingTop: "84px",
          alignItems: "center"
          // "@media(max-width: 820px)": {
          //   display: "none"
          // }
        }}
      >
        <Landings/>
      </div>
    </ContentWrapper>
  </div>
);

export default Landing;
