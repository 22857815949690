import React from "react";
import ContentWrapper from "./ContentWrapper";

const questionsAndAnswers = [
  {
    question: "What is ARIS?",
    answer:
      "ARIS stands for Astronomical Reasearch Institute, Sri Lanka, Which is founded by group of highly qualified young professionals under Celestia Group."
  },
  {
    question: "What does ARIS do?",
    answer:
      "We help young astronomers in Sri Lanka to find their path in astronomy."
  },
  {
    question: "Can I join ARIS?",
    answer:
      "Absolutely you can fill out the application form here. we will get back to you as soon as we can."
  },
  {
    question: "We want do a night camp in Our School? Does ARIS conduct Night Camps?",
    answer: "Absolutely. We conduct night camps workshops and any astronomy related events as long as the the event helps Sri Lankans to graw their knowleage in astronomy."
  },
  {
    question: "Is Pluto still considered a planet in 2019?",
    answer:
      "Pluto is a dwarf planet that lies in the Kuiper Belt, an area full of icy bodies and other dwarf planets out past Neptune. Pluto is very small, only about half the width of the United States and its biggest moon Charon is about half the size of Pluto."
  },
  {
    question: "What is the difference between astronomy and astrology?",
    answer:
      "Believe it or not, this is the most asked question that astronomers encounter. Many people do not understand the difference. In ancient times, they were considered one and the same. But the two disciplines were separated during the Age of Reason in the 17th century. Astrology is a practice of using the locations of the planets to look into a person's personality or predict the future. It is not a science and is considered a form of divination. By contrast, astronomy is the scientific study of the universe. Astronomers observe the objects in the night sky to try to determine their composition and learn more about the origin and structure of the universe."
  },
  {
    question: "Do I need an expensive telescope to enjoy astronomy?",
    answer: "Many people hesitate to get involved with astronomy because they believe it requires expensive equipment. The only thing you really need to enjoy the night sky is your eyes, a dark viewing location, and some patience. To get a better look at things, a pair of binoculars can provide a really good view. Many people will be surprised how many more stars and objects they can see with a decent pair of 10X binoculars. They collect much more light than the human eye and will bring much dimmer objects into view. You can even see Jupiter’s moons with binoculars. A simple camera tripod to steady the binoculars is also a good idea, since your arms can get tired very quickly."
  },
  {
    question: "Why can't I see very many stars at night?",
    answer:
      "If you live near a big city, you may not be able to see a lot of stars. The reason for this is light pollution. Dust and water vapor in the atmosphere reflects the bright city lights back down towards the ground. This “light pollution” tends to be brighter than some of the dim stars and other deep sky objects, essentially hiding them from view. To truly appreciate the night sky, you must get as far away from city lights as possible. There is no more beautiful sight then the band of the Milky Way stretching across a dark sky. We can all help to combat light pollution by convincing our local authorities to use more efficient light fixtures that shine the light on the ground and block it from going up into the sky."
  },
  {
    question: "Where does space begin?",
    answer:
      "Space is defined as the area above the Earth’s atmosphere. But there is no specific boundary since the atmosphere gradually thins out as you move farther away from the Earth. However, NASA awards astronaut status to anyone who flies above 50 miles (80 km)."
  },
  {
    question: "How many stars are there in the universe?",
    answer:
      "The universe is unbelievably huge. There are billions of galaxies and each galaxy contains billions of stars. The latest estimates from astronomers say that there are a staggering 300 sextillion stars in the known universe. That is a 3 followed by 23 zeros, or 3 trillion times 100 billion. That represents several stars for every grain of sand on Earth."
  }
];

const linkCss = {
  textDecoration: "underline",
  color: "#c81c2e",
  fontWeight: "400"
};

const FAQs = () => (
  <section id="faq" css={{ backgroundColor: "#ffffff" }}>
    <ContentWrapper>
      <div
        css={{
          padding: "110px 0 60px 0",
          "@media(max-width: 780px)": {
            padding: "64px 0 60px 0"
          }
        }}
      >
        <h1>Frequently Asked Questions</h1>
        <div
          css={{
            paddingTop: "36px",
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            "@media(max-width: 1190px)": {
              display: "block"
            }
          }}
        >
          {questionsAndAnswers.map(({ question, answer }) => (
            <div
              key={question}
              css={{
                padding: "0px 64px 40px 0px",
                "@media(max-width: 780px)": {
                  paddingRight: "24px"
                }
              }}
            >
              <h2
                css={{
                  paddingBottom: "10px",
                  fontWeight: "500"
                }}
              >
                {question}
              </h2>
              <p
                css={{ fontSize: "14px", color: "#000000", lineHeight: "20px" }}
              >
                {answer}
              </p>
            </div>
          ))}
          <div css={{ paddingTop: "12px" }}>
            <h2 css={{ paddingBottom: "10px", fontWeight: "500" }}>
              Have additional questions?
            </h2>
            <p css={{ fontSize: "14px", color: "#000000", lineHeight: "20px" }}>
              Feel free to Join the <strong>discussion</strong>. Or reach out to us via,
              <a
                rel="external noopener"
                target="_blank"
                css={linkCss}
                href="mailto:research@astronomers.lk"
              >
                {" "} research@astronomers.lk
              </a>{" "}
              or on{" "}
              <a
                rel="external noopener"
                target="_blank"
                css={linkCss}
                href="https://fb.com/astronomers.lk"
              >
                Facebook.
              </a>
            </p>
          </div>
        </div>
      </div>
    </ContentWrapper>
  </section>
);

export default FAQs;
