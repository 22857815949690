import React from "react";
import { css } from "glamor";

// import Header from "ASASCHeader";
// import ActionButton from "ActionButton";
import ContentWrapper from "./ContentWrapper";

// import ASASCCover from "../../assets/img/event_covers/ASASC_2019_banner.png";
import ISACLogo from "../assets/img/logo/IASCLogo.png";
import ARISLogo from "../assets/img/logo/aris.jpg";

import backgroundCubes from "../assets/img/backgrounds/backgroundCubes.svg";

const ASASCCover =
  "https://res.cloudinary.com/astronomerslk/image/upload/v1577297113/Event%20Covers/yuqpygnle4jsbj17qngt.jpg";

const applyButtonCSS = {
  margin: "25px 0 100px 0",
  "@media(max-width: 820px)": {
    margin: "30px 0 45px auto"
  }
};

const caretCSS = (backgroundColor) =>
  css({
    backgroundColor,
    width: "26px",
    height: "4px"
  });

const SolarEclipse = () => (
  <div
    id="introduction"
    css={{
      width: "100%",
      background: `url(${backgroundCubes}) no-repeat center center`,
      backgroundSize: "cover",
      "@media(max-width: 600px)": {
        minHeight: "600px"
      }
    }}
  >
    {/*<Header standaloneVersion={false} />*/}
    <ContentWrapper>
      <section
        css={{
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          "@media(max-width: 820px)": {
            textAlign: "center",
            display: "block"
          }
        }}
      >
        <div
          css={{
            "@media(min-width: 1200px)": {
              paddingRight: "50px",
              paddingTop: "100px"
            },
            "@media (min-width: 821px) and (max-width: 1199px)": {
              paddingRight: "50px",
              paddingTop: "100px"
            },
            "@media (max-width: 820px)": {
              paddingTop: "80px"
            }
          }}
        >
          <div
            css={{
              paddingRight: "43px",
              "@media (max-width: 920px)": { paddingRight: "0px" }
            }}
          >
            <h1 css={{ paddingTop: "40px" }}>
              Annular Solar Eclipse 2019 : Live From Jaffna - ARIS
            </h1>
            <div
              css={{
                display: "grid",
                gridTemplateColumns: "auto auto 1fr",
                gridColumnGap: "4px",
                padding: "13px 0px 27px 0px",
                "@media(max-width: 820px)": {
                  paddingBottom: "24px"
                },
                "@media(max-width: 1120px)": {
                  display: "none"
                }
              }}
            >
              <div {...caretCSS("#00205b")} />
              <div {...caretCSS("#c81c2e")} />
              <div {...caretCSS("#fedb01")} />
            </div>
          </div>
          <br />

          {/* <iframe width="853" height="480" src="https://www.youtube.com/embed/xAtWIeqMS4E" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}

          <iframe
            title="Solar Eclipse Live Stream"
            src="https://go.astronomers.lk/live"
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            justify="center"
            css={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
              padding: "auto",
              width: "1024px",
              height: "560px",
              "@media(max-width: 760px)": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "auto",
                padding: "auto",
                width: "560px",
                height: "560px"
              },
              "@media(max-width: 1115px)": {
                paddingTop: "40px",
                width: "100%",
                height: "100%"
              }
            }}
          />

          {/* <img
            src={ASASCCover}
            css={{
              padding: "2rem 1rem",
              width: "100%"
            }}
            alt="ARIS Illustration"
          /> */}

          {/* <h3
          css={{
            padding: "4px 0px 4px 0px",
            color: "#575757",
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "24px",
            "@media(max-width: 820px)": {
              paddingTop: "20px",
              fontSize: "14px",
              lineHeight: "1.6"
            }
          }}
        >
          Selected Teams
        </h3> */}

          {/* <h2
          css={{
            color: "black",
            lineHeight: "1.2",
            paddingTop: "20px",
            paddingBottom: "20px"
          }}
        >
          <small>
            35 Teams have been selected for the second All-Sri Lanka asteroid
            search campaign. If you are not selected this time, don't be
            discouraged. There will be another campaign in a few months, and
            you will be top contenders for the selections. click below to see the full list of selected teams.
          </small>
        </h2> */}

          <h2
            data-cy="aris-tagline"
            css={{
              padding: "30px 0 30px",
              color: "#575757",
              fontWeight: 500,
              lineHeight: "30px",
              "@media(max-width: 820px)": {
                paddingTop: "20px",
                fontSize: "15px",
                lineHeight: "1.6"
              }
            }}
          >
            <strong>
              Annular Solar Eclipse Public Event, You all are Invited.
              <br />
            </strong>
          </h2>

          <p
            css={{
              lineHeight: "24px",
              textAlign: "left",
              fontColor: "#000000",
              marginBottom: "10px"
            }}
          >
            <b>Astronomical Research Institute, Sri Lanka (ARIS)</b>{" "}
            collaborating with <b>Anandian Astronomical Association</b>,{" "}
            <b>Star Party group</b> and <b>Mount K Adventure Resort</b>{" "}
            organizes a <b>public camp</b> for{" "}
            <b>observing the annular solar eclipse</b> which will be happen on{" "}
            <b>26th of December, 2019</b> and will be visible to Asia and
            Australia. We organize this event at ancient Dutch fort of{" "}
            <b>Jaffna</b> which is located in the Northern part of the Sri
            Lanka. We plan to conduct the <b>public event</b> in 3 main
            languages (Sinhala, Tamil and English) in Sri Lanka. The event will
            be <b>started at 0800h</b> and will be ended at <b>1130 h.</b>
            <br />
          </p>

          <p
            css={{
              lineHeight: "24px",
              textAlign: "left",
              fontColor: "#000000",
              marginBottom: "10px"
            }}
          >
            We will use our telescopes (
            <b>main telescope: Meade LXD 75; 6” Schmidt Newtonian</b>) equipped
            with white light full-aperture solar filters (Thousand Oaks 2+ glass
            coated) and we are planning to facilitate participants with{" "}
            <b>solar eclipse observing glasses</b> to view the event.
            Furthermore, we will live webcast the entire solar eclipse via a
            DSLR camera equipped with a white light filter and a{" "}
            <b>Meade CORONADO PST H-alpha telescope</b> with GPS coordinates and
            the time in UT and LST.
          </p>

          <p
            css={{
              lineHeight: "24px",
              textAlign: "left",
              fontColor: "#000000"
            }}
          />

          <h3
            css={{
              padding: "16px 0px 4px 0px",
              color: "#575757",
              fontWeight: 600,
              fontSize: "18px",
              lineHeight: "24px",
              "@media(max-width: 820px)": {
                paddingTop: "24px",
                fontSize: "14px",
                lineHeight: "1.6"
              }
            }}
          >
            Event is conducted completely free of charge, You all are invited.
          </h3>
        </div>
      </section>
    </ContentWrapper>
  </div>
);

export default SolarEclipse;
