import React from "react";
import { css } from "glamor";

import Header from "./Header";
import ActionButton from "./ActionButton";
import ContentWrapper from "./ContentWrapper";

import landingImg from "../assets/img/covers/undraw_Astronaut_kwwa.svg";
import ISACLogo from "../assets/img/logo/IASCLogo.png";
import ARISLogo from "../assets/img/logo/aris.jpg";

import word from "../assets/img/logo/qhacksWordmark-colored.svg";
import backgroundCubes from "../assets/img/backgrounds/backgroundCubes.svg";

const applyButtonCSS = {
  margin: "25px 0 40px 0",
  "@media(max-width: 820px)": {
    margin: "30px 0 45px auto"
  }
};

const caretCSS = (backgroundColor) =>
  css({
    backgroundColor,
    width: "26px",
    height: "4px"
  });

const TheNextBigThing = () => (
  <div
    id=""
    css={{
      width: "100%",
      background: `url(${backgroundCubes}) no-repeat center center`,
      backgroundSize: "cover",
      "@media(max-width: 600px)": {
        minHeight: "600px"
      }
    }}
  >
    {/*<Header standaloneVersion={false} />*/}
    <ContentWrapper>
      <section
        css={{
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          "@media(max-width: 820px)": {
            textAlign: "center",
            display: "block"
          }
        }}
      >
        <div
          css={{
            "@media(min-width: 1200px)": {
              paddingRight: "50px",
              paddingTop: "100px"
            },
            "@media (min-width: 821px) and (max-width: 1199px)": {
              paddingRight: "50px",
              paddingTop: "100px"
            },
            "@media (max-width: 820px)": {
              paddingTop: "80px"
            }
          }}
        >
          <div
            css={{
              paddingRight: "43px",
              "@media (max-width: 920px)": { paddingRight: "0px" }
            }}
          >
            <h1 css={{ paddingTop: "40px" }}>The Big Event</h1>
            <div
              css={{
                display: "grid",
                gridTemplateColumns: "auto auto 1fr",
                gridColumnGap: "4px",
                padding: "13px 0px 27px 0px",
                "@media(max-width: 820px)": {
                  paddingBottom: "24px"
                },
                "@media(max-width: 1120px)": {
                  display: "none"
                }
              }}
            >
              <div {...caretCSS("#00205b")} />
              <div {...caretCSS("#c81c2e")} />
              <div {...caretCSS("#fedb01")} />
            </div>
          </div>
          <br />
          <div css={{ padding: "16px" }}>
            <img
              data-cy="qhacks-crown"
              src={ISACLogo}
              width="100px"

              alt="ISAC LOGO"
            />
            <img
              src={ARISLogo}
              width="150px"
              css={{ paddingLeft: "8px" }}
              alt="ARIS LOGO"
            />
          </div>
          <h1>ASASC</h1>
          <h2
            data-cy="aris-information"
            css={{
              color: "#00205b",
              fontWeight: 600,
              fontSize: "22px",
              paddingBottom: "30px",
              "@media(max-width: 820px)": {
                paddingBottom: "24px"
              }
            }}
          >ALL-Sri Lanka ASTEROID Search Campaign • May 14- June 10, 2020</h2>
          <h2
            data-cy="qhacks-tagline"
            css={{
              padding: "20px 0 30px",
              color: "#575757",
              fontWeight: 500,
              lineHeight: "30px",
              "@media(max-width: 820px)": {
                paddingTop: "20px",
                fontSize: "15px",
                lineHeight: "1.6"
              }
            }}
          >
            <strong>3rd All Sri Lankan Asteroid Searching Campaign (ASASC 2020)</strong>
            <br />
            Join us for an unforgettable experience!
            <br />
          </h2>

          <p css={{ lineHeight: "24px", textAlign: "left", fontColor: "#000000" }}>
            It's time to detect asteroids before they engulf you! Grab the chance of finding asteroids through real <br />
        data analysis and of putting your preferite names on them! Discovering something in outer space is <br />
        an amazing opportunity, but naming them is definitely unique! Astronomical Research Institute, Sri Lanka <br />
        in collaboration with International Astronomical Search Collaboration presents <br />
        the All Sri Lankan Asteroids Searching Campaign.<br />
        What are you  waiting for? Be part of this exhilarating experience!
          </p>

          <ActionButton
            dataCy="login-button"
            backgroundColor="#ffffff"
            foregroundColor="#00205b"
            style={applyButtonCSS}
            link="/ASASC2020v3"
            target="_self"
            type="rounded"
          >
            Go to Campaign page
          </ActionButton>
        </div>
        <div
          css={{
            position: "relative",
            textAlign: "right",
            display: "flex",
            paddingTop: "84px",
            alignItems: "center",
            "@media(max-width: 820px)": {
              display: "none"
            }
          }}
        >
          <img src={landingImg} css={{ width: "100%" }} alt="Rocket" />
        </div>
      </section>
    </ContentWrapper>
  </div>
);

export default TheNextBigThing;
