import React from "react";
import fetch from "unfetch";
import Helmet from "react-helmet";
import ApolloClient from "apollo-boost";
import * as Sentry from "@sentry/browser";
import { ApolloProvider } from "react-apollo";
import Raven from 'raven-js';

import "./index.css";
import FAQs from "../components/FAQs";
import pjson from "../../package.json";
import About from "../components/About";
import Footer from "../components/Footer";
import Podcast from "../components/Podcast";
import Landing from "../components/Landing";
import SolarEclipse from "../components/SolarEclipse";
import Sponsors from "../components/Sponsors";
import OurTeam from "../components/OurTeam";
import EventSchedule from "../components/EventSchedule";
import MailingListSignup from "../components/MailingListSignup";
import HackerTestimonials from "../components/HackerTestimonials";
import TheNextBigThing from "../components/TheNextBigThing";
import DisqusComments from "../components/ASASCComponents/DisqusComments";

import favicon from "../assets/img/icons/favicon.ico";
import sharingImage from "../assets/img/logo/aris.jpg";

const ARISCover =
  "https://res.cloudinary.com/astronomerslk/image/upload/v1577297113/Event%20Covers/yuqpygnle4jsbj17qngt.jpg";

export default () => {
  let environment = process.env.NETLIFY_ENV;

  switch (environment) {
    case "deploy-preview":
      environment = "development";
    case "branch-deploy":
      environment = "staging";
    case "production":
      environment = "production";
    default:
      environment = "development";
  }

  if (environment !== "development") {
    Raven.config('https://0838f2c5401347329da9bb6f8f28d4e6@sentry.io/1443292', {
      environment: 'production'
    }).install();
  }

  const BASE_URL = "https://astronomers.lk/graphql";

  const apolloClient = new ApolloClient({
    uri: BASE_URL,
    fetch
  });

  const disqusShortname = "astronomerslk";

  const disqusConfig = {
    url: "https://astronomers.lk",
    identifier: "Home",
    title: "Astronomical Research Institute, Sri Lanka"
  };

  return (
    <ApolloProvider client={apolloClient}>
      <div css={{ overflowX: "hidden" }}>
        <Helmet
          title="Astronomical Research Institute | Sri Lanka | 2019"
          meta={[
            {
              name: "google-site-verification",
              content: "S9aS4ItxzCJNaWol7mfOmUCMHJkmevrDOfYAOB34N4E"
              // content: "Urnxreea8HMeUWKtG7dfcfHdospq8p_s6FixHtirFjE"
            },
            {
              name: "google-site-verification",
              content: "Urnxreea8HMeUWKtG7dfcfHdospq8p_s6FixHtirFjE"
            },
            {
              name: "description",
              content:
                "Astronomical Research Institute, Sri Lanka"
            },
            {
              name: "keywords",
              content:
                "Astronomy, Research, ARIS, UFO, Telescopes, Asteroids, Astronomers Sri Lanka, Institute of Astronomy Sri Lanka, S&T Group, Astro Minds, Tharulowa"
            },
            {
              name: "viewport",
              content: "width=device-width, initial-scale=1.0"
            },
            {
              name: "twitter:card",
              content: "summary"
            },
            {
              name: "twitter:site",
              content: "@astronomerslk"
            },
            {
              name: "twitter:creator",
              content: "@Sankhalk"
            },
            {
              property: "og:type",
              content: "website"
            },
            {
              property: "og:url",
              content: "https://www.astronomers.lk"
            },
            {
              property: "og:title",
              content: "ARIS | Astronomical Research Institute, Sri Lanka"
            },
            {
              property: "og:image",
              content: ARISCover
            },
            {
              property: "og:description",
              content:
                "The new generation of Sri Lankan Astronomy."
            },
            {
              property: "og:site_name",
              content: "astronomers.lk"
            },
            {
              property: "og:locale",
              content: "en_US"
            },
            {
              property: "og:app_id",
              content: "272240363691538"
            }
          ]}
          link={[
            {
              rel: "shortcut icon",
              href: `${favicon}`
            }
          ]}
        >
          <html lang="en" />
        </Helmet>
        <div>
          {/* <TheNextBigThing /> */}
          <Landing />
          <MailingListSignup />
          <About />
          {/*<HackerTestimonials />*/}
          <OurTeam />
          {/*<EventSchedule />*/}
          <Sponsors />
          <FAQs />
          <DisqusComments disqusShortname={disqusShortname} disqusConfig={disqusConfig} />
          {/*<Podcast />*/}
          <Footer />
        </div>
      </div>
    </ApolloProvider>
  );
};
