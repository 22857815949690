import React, { Component, Fragment } from "react";
import Slider from "react-slick";

import LandingCard from "./LandingCard";
import SpeakerSliderDots from "./SpeakerSliderDots";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class LandingsSlider extends Component {
  constructor(props) {
    super(props);
  }

  handleChange = (currentIndex) => this.setState({ currentIndex });

  componentWillMount() {
    this.setState({ currentIndex: 0 });
  }

  onSpeakerSliderDotsClicked = (num) => () => {
    this.slider.slickGoTo(num);
  };

  render() {
    const desktopSettings = {
      dots: false,
      arrows: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 6000,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 500,
      afterChange: (direction) => {
        this.handleChange(direction);
      }
    };

    const mobileSettings = {
      dots: false,
      arrows: false,
      infinite: false,
      afterChange: (direction) => {
        this.handleChange(direction);
      }
    };

    return (
      <Fragment>
        {/*Desktop*/}
        <div css={{
          width: "100%",
          position: "relative",
          zIndex: 3,
          padding: "40px 5px",
          textAlign: "center",
          "@media(max-width: 1120px)": {
            display: "none"
          }}}>
          <SpeakerSliderDots
            speakers={this.props.speakers}
            currentIndex={this.state.currentIndex}
            onClick={this.onSpeakerSliderDotsClicked}
          />
          <Slider {...desktopSettings} ref={(slider) => (this.slider = slider)}>
            {this.props.speakers.map((speaker) => (
              <LandingCard {...speaker} key={speaker.name} />
            ))}
          </Slider>
        </div>
        {/*Mobile*/}
        <div css={{
          width: "100%",
          position: "relative",
          paddingTop: "5%",
          zIndex: 3,
          "@media(min-width: 1120px)": {
            display: "none"
          }
        }}>
          <SpeakerSliderDots
            speakers={this.props.speakers}
            currentIndex={this.state.currentIndex}
            onClick={this.onSpeakerSliderDotsClicked}
          />
          <Slider {...mobileSettings} ref={(slider) => (this.slider = slider)}>
            {this.props.speakers.map((speaker) => (
              <LandingCard {...speaker} key={speaker.name} />
            ))}
          </Slider>
        </div>
      </Fragment>
    );
  }
}

export default LandingsSlider;
